<template>
    <div class="preview-modal">
        <b-modal ref="modal" size="lg" title="Preview" header-bg-variant="primary" header-text-variant="light" hide-footer no-close-on-esc no-close-on-backdrop>
            <div class="loader text-center" v-if="loading">
                <b-spinner></b-spinner>
            </div>
            <div class="approval-details" v-if="approvalPreview" >
                <EntryPreview :attributes="approvalPreview.attributes" :type="approvalPreview.type" :attachments="approvalPreview.attachments" />
                <b-button @click="showLinkedEntry" v-if="approvalPreview.linkedEntryAttributes" variant="outline-info" size="sm" class="mb-4"><FileLinkOutlineIcon /> View Linked Reflection</b-button>
                <ApprovalResponse @approve="approve" @reject="reject" ref="response" />
            </div>
        </b-modal>
        <b-modal ref="linkedModal" size="lg" title="Preview - Linked Entry" header-bg-variant="primary" header-text-variant="light" hide-footer>
            <EntryPreview v-if="approvalPreview && approvalPreview.linkedEntryAttributes" :attributes="approvalPreview.linkedEntryAttributes" type="" />
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../providers';
    import EntryPreview from "../external/approval/EntryPreview";
    import ApprovalResponse from "../external/approval/ApprovalResponse";
    import FileLinkOutlineIcon from 'vue-material-design-icons/FileLinkOutline';

    export default {
        name: "PreviewEntryModal",
        components: {ApprovalResponse, EntryPreview, FileLinkOutlineIcon},
        data() {
            return {
                loading: false,
                approvalPreview: null,
                itemId: null,
                type: null,
            }
        },
        methods: {
            show(itemId, type) {
                this.reset();
                this.itemId = itemId;
                this.type = type;
                this.$refs.modal.show();
                this.loadApprovalPreview();
            },
            showLinkedEntry() {
                this.$refs.linkedModal.show();
            },
            loadApprovalPreview() {
                this.loading = true;
                Providers.mentor.approvals.previewEntry(this.itemId, this.type).then((resp) => {
                    this.approvalPreview = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load entry preview', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            approve(comments) {
                Providers.mentor.approvals.approveEntry(this.itemId, this.type, comments).then(() => {
                    this.$emit('responded');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to approve entry', resp);
                });
            },
            reject(comments) {
                Providers.mentor.approvals.rejectEntry(this.itemId, this.type, comments).then(() => {
                    this.$emit('responded');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to return entry', resp);
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                if (this.$refs.modal && this.$refs.modal.$refs.response) {
                    this.$refs.modal.$refs.response.reset();
                }
                this.approvalPreview = null;
            }
        },

    }
</script>

<style scoped>

</style>
