<template>
    <div class="for-approval">
        <b-table :fields="fields" ref="table" :items="entryProvider" show-empty striped>
            <template v-slot:empty="">
                <span>No items pending approval</span>
            </template>
            <template v-slot:cell(entry.possibleActions)="data">
                <div class="actions">
                    <b-button variant="outline-secondary" size="sm" @click="preview(data.item)">Open</b-button>
                </div>
            </template>
        </b-table>

        <PreviewEntryModal ref="previewEntryModal" @responded="refresh" />
    </div>
</template>

<script>
    import Providers from '../../providers';
    import PreviewEntryModal from "../../components/mentor/PreviewEntryModal";

    export default {
        name: "ForApproval",
        components: {PreviewEntryModal},
        data() {
            return {
                fields: [
                    {
                        key: 'submitter',
                        label: 'Submitter',
                        sortable: true
                    },
                    {
                        key: 'type',
                        label: 'Type',
                        sortable: true
                    },
                    {
                        key: 'title',
                        sortable: true
                    },
                    {
                        label: 'Evidence',
                        key: 'entry.evidence'
                    },
                    {
                        label: 'Actions',
                        key: 'entry.possibleActions'
                    }
                ]
            }
        },
        methods: {
            entryProvider: function (ctx, callback) {
                this.isBusy = true;
                Providers.mentor.approvals.pendingApproval(ctx.currentPage, ctx.perPage).then(response => {
                    callback(response.body);
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.isBusy = false;
                });
                return null
            },
            preview(item) {
                this.$refs.previewEntryModal.show(item.itemId, item.itemType);
            },
            refresh() {
                this.$refs.table.refresh();
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../node_modules/cpdcloud-dashboard/src/assets/variables';

    .actions > * {
        margin: 0 4px;
    }

</style>